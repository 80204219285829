import type { WorkOrderCustomFieldType } from '@/graphql/types';
import type {
  IWorkOrderCustomFieldDateValue,
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValue,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValue,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValue,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValue,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValue,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValue,
  IWorkOrderCustomFieldUserValueInput,
} from '../types/workOrder';

// Note: オブジェクトの参照を切っている
export const convertCustomFieldValueToInputValue = ({
  customFieldTextValues = [],
  customFieldIntValues = [],
  customFieldFloatValues = [],
  customFieldSelectValues = [],
  customFieldDateValues = [],
  customFieldDatetimeValues = [],
  customFieldUserValues = [],
}: {
  customFieldTextValues?: IWorkOrderCustomFieldTextValue[];
  customFieldIntValues?: IWorkOrderCustomFieldIntValue[];
  customFieldFloatValues?: IWorkOrderCustomFieldFloatValue[];
  customFieldSelectValues?: IWorkOrderCustomFieldSelectValue[];
  customFieldDateValues?: IWorkOrderCustomFieldDateValue[];
  customFieldDatetimeValues?: IWorkOrderCustomFieldDatetimeValue[];
  customFieldUserValues?: IWorkOrderCustomFieldUserValue[];
}): {
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[];
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[];
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[];
  customFieldUserValues: IWorkOrderCustomFieldUserValueInput[];
} => {
  return {
    customFieldTextValues: customFieldTextValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        value: value.value,
      };
    }),
    customFieldIntValues: customFieldIntValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        value: value.value,
      };
    }),
    customFieldFloatValues: customFieldFloatValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        value: value.value,
      };
    }),
    customFieldSelectValues: customFieldSelectValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        optionId: value.optionId,
      };
    }),
    customFieldDateValues: customFieldDateValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        value: value.value,
      };
    }),
    customFieldDatetimeValues: customFieldDatetimeValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        value: value.value,
      };
    }),
    customFieldUserValues: customFieldUserValues.map((value) => {
      return {
        customFieldId: value.customFieldId,
        userId: value.user.id,
      };
    }),
  };
};

export const isNumericField = (type: WorkOrderCustomFieldType | undefined): boolean =>
  type === 'int' || type === 'float';
